import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonLp from "./ButtonLp";
import useAuth from "../hooks/zustand/useAuth";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { mockLanguages } from "../mock/mockData";
import { Img, Text } from "components";

const Header = ({
  changeColorWhileScroll = false,
  scrollToMarket,
  scrollToTrading,
  scrollToService,
  scrollToTestimonial,
  scrollToAward,
}) => {
  const isLogin = useAuth();
  const { t, i18n } = useTranslation();
  const [scrolled, setScrolled] = useState(false);
  const [isOpenMenu, setOpenMenu] = useState(false);
  const scrollToHome = () => {
    window.scrollTo({
      top: "0",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 80;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    i18next.changeLanguage("en");

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  function getInitialColor() {
    let initialColor;
    if (changeColorWhileScroll) {
      initialColor = "bg-blue-scfx";
    } else {
      initialColor = "bg-white";
    }
    if (changeColorWhileScroll && scrolled) {
      initialColor = "bg-gray-500";
    }
    return initialColor;
  }

  function getInitialImage() {
    let initialImage;
    if (changeColorWhileScroll) {
      initialImage = process.env.REACT_APP_LOGO_PATH;
      //initialImage = "/images/Produk-Kewangan-Main-Logo.png";
    } else {
      initialImage = process.env.REACT_APP_LOGO_PATH;
      //initialImage = "/images/Produk-Kewangan-Black-Logo.png";
    }
    if (changeColorWhileScroll && scrolled) {
      initialImage = process.env.REACT_APP_LOGO_BG_PATH;
    }
    return initialImage;
  }

  function changeLanguage(lng) {
    i18next.changeLanguage(lng, (err) => {
      console.warn("An error occured while changin language");
    });
  }
  const navigate = useNavigate();

  const handleLoginChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue === "investor") {
      // Redirect to "/sign-in"
      navigate("/sign-in");
    } else {
      navigate("sign-in-admin");
    }
  };
  return (
    <>
      <nav
        className={`h-20 z-[9999] ${getInitialColor()} sticky top-0 flex items-center duration-300`}
      >
        <div className="flex flex-wrap  justify-between items-center mx-auto max-w-screen-xl w-full p-4">
          <Link to="/" onClick={scrollToHome}>
            <img
              src={`${getInitialImage()}`}
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className="w-32 object-contain object-center"
            />
          </Link>
          <div className="sm:hidden">
            <ul className="flex gap-10">
              <li className="list-none">
                <Text
                  onClick={scrollToMarket}
                  className={` ${
                    scrolled ? "text-black-900" : "text-black-900"
                  } cursor-pointer`}
                >
                  Market
                </Text>
              </li>
              <li className="list-none">
                <Text
                  onClick={scrollToTrading}
                  className={` ${
                    scrolled ? "text-black-900" : "text-black-900"
                  } cursor-pointer`}
                >
                  Trading
                </Text>
              </li>
              <li className="list-none">
                <Text
                  onClick={scrollToService}
                  className={` ${
                    scrolled ? "text-black-900" : "text-black-900"
                  } cursor-pointer`}
                >
                  Services
                </Text>
              </li>
              <li className="list-none">
                <Text
                  onClick={scrollToTestimonial}
                  className={` ${
                    scrolled ? "text-black-900" : "text-black-900"
                  } cursor-pointer`}
                >
                  Testimonial
                </Text>
              </li>
              <li className="list-none">
                <Text
                  onClick={scrollToAward}
                  className={` ${
                    scrolled ? "text-black-900" : "text-black-900"
                  } cursor-pointer`}
                >
                  Awards
                </Text>
              </li>
            </ul>
          </div>
          <div className="space-x-2.5 flex flex-row sm:justify-end">
            {mockLanguages.length > 1 && (
              <select
                defaultValue="en"
                className="border-2 rounded-lg ring-0 text-sm "
                onChange={(e) => changeLanguage(e.target.value)}
                value={i18next.language}
              >
                {mockLanguages.map((langCode) => (
                  <option key={langCode} value={langCode}>
                    {langCode.toUpperCase()}
                  </option>
                ))}
              </select>
            )}
            <div className="sm:hidden">
              {isLogin.auth?.accessToken &&
              isLogin.auth?.role?.replace(/["']/g, "") === "user" ? (
                <Link to="/dashboard">
                  <ButtonLp className="uppercase" variant="primary">
                    {t("home.header.dashboard")}
                  </ButtonLp>
                </Link>
              ) : isLogin.auth?.accessToken &&
                isLogin.auth?.role?.replace(/["']/g, "") !== "user" &&
                isLogin.auth?.role !== null ? (
                <Link to="/en/dashboard/admin/user-list">
                  <ButtonLp className="uppercase" variant="primary">
                    {t("home.header.dashboard")}
                  </ButtonLp>
                </Link>
              ) : !isLogin.auth?.role && !isLogin.auth?.accessToken ? (
                //   <select
                //   className="border-none uppercase rounded-md bg-blue-500 text-sm font-medium text-white h-10"
                //   onChange={handleLoginChange}
                // >
                //   <option disabled="true" selected >Sign In</option>
                //   <option value="investor">Investor</option>
                //   <option value="broker">Broker</option>
                // </select>
                <div className="flex  gap-3">
                  <Link to={`/${i18n.language}/sign-in/`}>
                    <ButtonLp className="uppercase" variant="primary">
                      {t("home.header.signin")}
                    </ButtonLp>
                  </Link>
                  <Link to={`/${i18n.language}/sign-up/`}>
                    <ButtonLp className="uppercase" variant="primary">
                      {t("home.header.signup")}
                    </ButtonLp>
                  </Link>
                </div>
              ) : null}
            </div>
            <div
              className="items-center hidden sm:flex sm:visible"
              onClick={() => {
                setOpenMenu(!isOpenMenu);
              }}
            >
              <Img src="/images/home/menu.svg" />
            </div>
          </div>
        </div>
      </nav>
      <div
        style={{
          top: `${window.scrollY + 78}px`,
        }}
        className={`absolute z-[99999] h-[22rem] bg-[#ffffff] w-full ${
          !isOpenMenu ? "hidden" : "inline"
        }`}
      >
        <div className="flex flex-col p-5">
          <div
            onClick={() => {
              setOpenMenu(false);
              scrollToMarket();
            }}
            className="flex flex-row items-center w-full pb-3 cursor-pointer"
          >
            <div className="flex flex-col w-[15%]">
              <Img className="w-[30px]" src="/images/menu/market.svg" />
            </div>
            <div className="flex flex-col w-[45%] items-center">
              <Text className="text-[1.3rem] pb-2">Market</Text>
            </div>
            <div className="flex flex-col w-[40%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div
            onClick={() => {
              setOpenMenu(false);
              scrollToTrading();
            }}
            className="flex flex-row items-center w-full pb-3 cursor-pointer"
          >
            <div className="flex flex-col w-[15%]">
              <Img className="w-[30px]" src="/images/menu/trade.svg" />
            </div>
            <div className="flex flex-col w-[45%] items-center">
              <Text className="text-[1.3rem] pb-2">Trading</Text>
            </div>
            <div className="flex flex-col w-[40%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div
            onClick={() => {
              setOpenMenu(false);
              scrollToService();
            }}
            className="flex flex-row items-center w-full pb-3 cursor-pointer"
          >
            <div className="flex flex-col w-[15%]">
              <Img className="w-[30px]" src="/images/menu/services.svg" />
            </div>
            <div className="flex flex-col w-[45%] items-center">
              <Text className="text-[1.3rem] pb-2">Services</Text>
            </div>
            <div className="flex flex-col w-[40%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div
            onClick={() => {
              setOpenMenu(false);
              scrollToTestimonial();
            }}
            className="flex flex-row items-center w-full pb-3 cursor-pointer"
          >
            <div className="flex flex-col w-[15%]">
              <Img className="w-[30px]" src="/images/menu/testimonial.svg" />
            </div>
            <div className="flex flex-col w-[45%] items-center">
              <Text className="text-[1.3rem] pb-2">Testimonial</Text>
            </div>
            <div className="flex flex-col w-[40%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div
            onClick={() => {
              setOpenMenu(false);
              scrollToAward();
            }}
            className="flex flex-row items-center w-full pb-3 cursor-pointer"
          >
            <div className="flex flex-col w-[15%]">
              <Img className="w-[30px]" src="/images/menu/awards.svg" />
            </div>
            <div className="flex flex-col w-[45%] items-center">
              <Text className="text-[1.3rem] pb-2">Awards</Text>
            </div>
            <div className="flex flex-col w-[40%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full border-b-2"></div>
          <div className="flex flex-row w-full justify-center py-5">
            {isLogin.auth?.accessToken &&
            isLogin.auth?.role?.replace(/["']/g, "") === "user" ? (
              <Link to="/dashboard">
                <ButtonLp className="uppercase" variant="primary">
                  {t("home.header.dashboard")}
                </ButtonLp>
              </Link>
            ) : isLogin.auth?.accessToken &&
              isLogin.auth?.role?.replace(/["']/g, "") !== "user" &&
              isLogin.auth?.role !== null ? (
              <Link to="/en/dashboard/admin/user-list">
                <ButtonLp className="uppercase" variant="primary">
                  {t("home.header.dashboard")}
                </ButtonLp>
              </Link>
            ) : !isLogin.auth?.role && !isLogin.auth?.accessToken ? (
              //   <select
              //   className="border-none uppercase rounded-md bg-blue-500 text-sm font-medium text-white h-10"
              //   onChange={handleLoginChange}
              // >
              //   <option disabled="true" selected >Sign In</option>
              //   <option value="investor">Investor</option>
              //   <option value="broker">Broker</option>
              // </select>
              <div className="flex  gap-3">
                <Link to={`/${i18n.language}/sign-in/`}>
                  <ButtonLp className="uppercase" variant="primary">
                    {t("home.header.signin")}
                  </ButtonLp>
                </Link>
                <Link to={`/${i18n.language}/sign-up/`}>
                  <ButtonLp className="uppercase" variant="primary">
                    {t("home.header.signup")}
                  </ButtonLp>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
