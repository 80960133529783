import React, { useEffect, useState } from "react";
import { firebaseAuth } from "../../lib/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
// components
import { FormProvider, RHFInputAddon } from "../../components/RHForm";
import ButtonLp from "../../components/ButtonLp";
import OTPInput from "react-otp-input";
import { enqueueSnackbar } from "notistack";
import useAuth from "../../hooks/zustand/useAuth";
import useHomeSlider from "../../hooks/zustand/useHomeSlider";
import { useTranslation } from "react-i18next";
import { Img, Heading, Text } from "components";

const ForgotPassword = () => {
  const [otp, setOtp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submited, setSubmit] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [country, setCountry] = useState("+60");
  const auth = useAuth();
  const navigate = useNavigate();
  const homeSliderStore = useHomeSlider();
  const [bgImg, setBgImg] = useState(process.env.REACT_APP_LOGIN_BG_IMG);
  const { t, i18n } = useTranslation();

  const schema = yup.object().shape({
    phone: yup
      .string()
      .required("Phone is required")
      .matches(/^\d+$/, "Number only!")
      .min(9, "Minimum 9 digit numbers")
      .max(13, "Maximum 13 digit numbers"),
  });

  const defaultValues = {
    phone: "",
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch, handleSubmit, reset } = methods;

  const values = watch();

  const configureCaptcha = () => {
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          firebaseAuth,
          "recaptcha-container",
          {
            size: "invisible",
            callback: async () => {
              setSubmit(true);
              const appVerifier = window.recaptchaVerifier;
              const phoneNumber = country + values.phone;

              try {
                const confirmationResult = await signInWithPhoneNumber(
                  firebaseAuth,
                  phoneNumber,
                  appVerifier
                );
                window.confirmationResult = confirmationResult;
                enqueueSnackbar("Successfully send OTP code.", {
                  variant: "success",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
                setGenerate(true);
              } catch (error) {
                // console.log('erroror', error?.message);
                if (error?.message.toString().includes("too-many-request")) {
                  enqueueSnackbar("Too many request. Please try again later", {
                    variant: "error",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  });
                } else {
                  enqueueSnackbar(
                    "An error occurred while generating OTP code.",
                    {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                    }
                  );
                }
              } finally {
                setSubmit(false);
              }
              return;
            },
          }
        );
      }
    } catch (error) {
      console.warn("Error occured");
    }
  };

  const onSubmit = async () => {
    try {
      setSubmit(true);
      const res = await auth.checkCredentials({ phoneNumber: values.phone });
      if (!res.isExist) {
        enqueueSnackbar("Number not found in our app.", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        reset();
      }
      if (res.isExist) {
        setSubmit(true);
        configureCaptcha();
        window.recaptchaVerifier.verify();
      }
    } catch (error) {
      enqueueSnackbar("An error occured while generate OTP.", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      console.error("An error occured");
    }
  };

  async function Verify() {
    try {
      setLoading(true);
      await window.confirmationResult.confirm(otp);
      const res = await auth.resetPasswordByPhone({
        phoneNumber: values.phone,
      });
      auth.setDefaultPassword(res.password);
      navigate("/sign-in");
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Wrong OTP code. Try again", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const handleChangeCountry = () => {
    if (country === "+60") {
      setCountry("+62");
    } else {
      setCountry("+60");
    }
  };

  const initialGetBgImg = async () => {
    const resp = await homeSliderStore.getBgImg();
    if (resp.status === 200) {
      if (resp?.data?.slider?.bg_img ?? "" !== "")
        setBgImg(resp.data.slider.bg_img);
    }
  };

  useEffect(() => {
    initialGetBgImg();
  }, []);

  return (
    <>
      <div className="flex flex-row w-full sm:flex-col-reverse">
        <div className="w-1/2 flex flex-col md:flex-row md:w-full sm:flex-row sm:w-full">
          <div className="w-full flex">
            <div className=" bg-primary-primary h-screen md:h-[100%] w-full sm:p-5 ">
              <div className=" m-auto   bg-cover bg-no-repeat ">
                <div className="mt-[3.75rem] flex flex-col gap-[3.00rem]">
                  <Img
                    src="/images/img_illustration.svg"
                    alt="Main Illustration"
                    className="h-[22.13rem]  object-contain "
                  />
                  <div className="mx-[1.75rem] flex flex-col items-center gap-[1.50rem] md:mx-0">
                    <Heading
                      size="heading_h3"
                      as="h2"
                      className="ml-[1.13rem] text-center text-[1.75rem] font-extrabold leading-[2.13rem] text-black-900 md:ml-0 md:text-[1.63rem] sm:text-[1.50rem]"
                    >
                      <>
                        Buy & sell Each Digital
                        <br />
                        Cryptocurrency
                      </>
                    </Heading>
                    <Text
                      as="p"
                      className="text-center text-[0.88rem] font-normal leading-[1.31rem] text-black-900"
                    >
                      <>
                        Easily Buy bitcoin and other cryptocurrencies using
                        <br />
                        wide range of payment option. Discover exclusive
                        <br />
                        digital collection of us.
                      </>
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`w-1/2 px-5 overflow-hidden ${
            generate ? "hidden" : "block"
          } flex flex-col sm:w-full`}
        >
          <div className=" w-full flex justify-end pt-5
          ">
            <img
              src={process.env.REACT_APP_LOGO_PATH}
              //src='/images/Produk-Kewangan-Black-Logo.png'
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className="w-32"
            />
          </div>
          <div className="w-full lg:w-[30rem] bg-white overflow-hidden  px-5  py-10 mx-auto">
            <h1 className="text-xl lg:text-2xl font-semibold mt-10 mb-2">
              Recover Your Password
            </h1>
            {/* <h2 className="text-sm lg:text-md font-semibold text-black-900 mb-2.5">
                Already have an account?{" "}
                <Link
                  to={`/${i18n.language}/sign-in`}
                  className="text-blue-500"
                >
                  Sign in here
                </Link>
              </h2> */}
            <h2 className="text-sm lg:text-md font-semibold text-black-900 mb-7">
              Click{" "}
              <span
                className="text-blue-500 cursor-pointer"
                onClick={handleChangeCountry}
              >
                here
              </span>{" "}
              if you're {country === "+60" ? "Indonesian" : "Malaysian"}
            </h2>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-4 lg:gap-8 w-full mx-auto">
                <div id="recaptcha-container" />
                <RHFInputAddon name="phone" label="Phone" addon={country} />
                <ButtonLp
                  type="submit"
                  className="w-full mx-auto"
                  loading={submited}
                >
                  Continue
                </ButtonLp>
              </div>
            </FormProvider>
            <div className="mt-5 text-center">
              <Link to="/" className="font-semibold text-blue-500 text-sm">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
        <div
          className={`w-1/2  px-5 overflow-hidden ${
            generate ? "block" : "hidden"
          } flex flex-col sm:w-full`}
        >
          <div className="w-full flex justify-end pt-5">
            <img
              src="/images/logo-dark.png"
              //src ='/images/Produk-Kewangan-Black-Logo.png'
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className="w-24 "
            />
          </div>
          <div className="w-full lg:w-[30rem] bg-white overflow-hidden  px-5  py-14 mx-auto">
            <div className="flex flex-col items-center">
              <h1 className="text-xl lg:text-2xl font-semibold mt-10 mb-2">
                OTP Code Confirmation
              </h1>
              <h2 className="text-sm lg:text-md font-semibold text-gray-400 text-center mb-10">
                We've sent you an OTP code. Please check your message
              </h2>
            </div>

            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => (
                <input {...props} className="!w-10 rounded-lg" />
              )}
              containerStyle="space-x-4 w-full items-center justify-center"
              inputType="number"
            />
            <div className="mt-7 w-full flex justify-center">
              <ButtonLp
                type="button"
                className="w-full mx-auto"
                onClick={() => Verify()}
                loading={loading}
              >
                {loading ? "Please wait..." : "Reset password"}
              </ButtonLp>
            </div>
            <div className="mt-5 text-center">
              <Link to="/" className="font-semibold text-blue-500 text-sm">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
