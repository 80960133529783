import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Img, Text } from "components";

const currYear = new Date().getFullYear();
const projectTitle = process.env.REACT_APP_PROJECT_TITLE ?? "SC FX";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer >
      {/* <div className='bg-[#0e0e42] pt-10'> */}
      <div className="bg-[#0D0D2B] pt-10 flex flex-col items-center">
        <div className="flex flex-col w-[90%] items-center justify-center">
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-[10%]">
              <img
                src={process.env.REACT_APP_LOGO_BG_PATH}
                alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
                className="w-32 sm:w-full object-contain object-center mr-5"
              />
            </div>
          </div>
          <div className="flex flex-row justify-start items-start w-full">
            <div className="flex flex-col gap-3 ">
              <Text className="text-white-a700_99 py-5 font-semibold text-[1.2rem]  ">
                Company
              </Text>
              <Link to="/about-us">
                <Text className="text-white-a700_99 text-sm cursor-pointer">
                  {t("home.footer.AboutUs")}
                </Text>
              </Link>
              <Link to="/news">
                <Text className="text-white-a700_99 text-sm cursor-pointer">
                  {t("home.footer.News")}
                </Text>
              </Link>
              <Link to="/aml-policy">
                <Text className="text-white-a700_99 text-sm cursor-pointer">
                  {t("home.footer.AMLPolicy")}
                </Text>
              </Link>
              <Link
                to="/return-policy"
              >
                <Text className="text-white-a700_99 text-sm">
                  {t("home.footer.ReturnPolicy")}
                </Text>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-row h-[2.5rem] border-b-[0.01rem] opacity-30 w-[90%]"></div>
        <div className="flex flex-row w-[90%] pt-5 gap-[3rem] sm:gap-[1rem]">
          <div className="flex flex-col h-1">
          <Text className="text-white-a700_99 text-sm sm:text-[0.68rem]">© {currYear}. All rights reserved</Text>
          </div>
          <div className="border-l-[0.01rem] h-[25px] flex items-center">
          <Link to="/privacy-policy" >
          <Text className="text-white-a700_99 pl-3 sm:text-[0.7rem]">
          {t("home.footer.PrivacyPolicy")}
          </Text>
                </Link>
          </div>
          <div className="border-l-[0.01rem] h-[25px] flex items-center">
          <Link
                  to="/risk-disclosure"
                >
                  <Text className="text-white-a700_99 pl-3 sm:text-[0.7rem]">
                  {t("home.footer.RiskDisclosure")}
                  </Text>
                  
                </Link>
          </div>
          <div className="h-[3rem]"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
