import Button from "components/Button/Button";
import Multiligual from "components/Multiligual";
import React, { useEffect, useState } from "react";
import { Img, Text } from "components";
import { convertTime } from "lib/table-date.formatter";
import { Link } from "react-router-dom";
import toRinggit from "lib/currencyFormatter";
import { useTranslation } from "react-i18next";
import {
  MdDashboardCustomize,
  MdBookmark,
  MdNextWeek,
  MdOutlinePeople,
  MdLogout,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import usePersonalInformation from "hooks/zustand/usePersonalInformation";
import useAuth from "hooks/zustand/useAuth";
import HeaderInvestmentMenu from "../HeaderInvestmentMenu";
import { MdKeyboardArrowDown, MdWallet } from "react-icons/md";
import HeaderProfileMenu from "../HeaderProfileMenu";

export default function Header({ userInfo }) {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [isOpenMenu, setOpenMenu] = useState();
  const [isOpenInvestmentSub, setOpenInvestmentSub] = useState(false);
  const [isOpenProfileSub, setOpenProfileSub] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const usePersonal = usePersonalInformation();
  const auth = useAuth();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  });

  const handleLogout = () => {
    usePersonal.reset();
    auth.logout();
    navigate(`/${i18n.language}/sign-in`);
  };
  return (
    <>
      <div className="container pb-5">
        <div className="flex flex-col w-full">
          <div className="flex flex-row h-[4rem] sm:h-[3rem] sm:pt-2 w-full">
            <div className="w-[10%] sm:w-[15%] flex flex-col items-center justify-center">
              <Link to="/dashboard">
                <Img
                  className="w-32  sm:ml-2 sm:mt-2 "
                  src={process.env.REACT_APP_LOGO_PATH}
                />
              </Link>
            </div>
            <div className="w-[20px]  border-r-2 sm:mr-3 "></div>
            <div className="flex flex-col w-[40%] sm:hidden ">
              <div className="flex flex-row w-full h-[4rem] items-center">
                <Link to="/dashboard">
                  <div className="ml-[3rem] flex h-[4rem] flex-col w-1/4 text-center hover:border-b-2 cursor-pointer border-b-blue-600  ">
                    <Text className="mt-[1.3rem]">Dashboard</Text>
                  </div>
                </Link>
                <Link to="/dashboard/market-list">
                  <div className="ml-[3rem] flex h-[4rem] flex-col w-1/4 text-center hover:border-b-2 cursor-pointer border-b-blue-600  ">
                    <Text className="mt-[1.3rem]">WatchList</Text>
                  </div>
                </Link>
                <Link to="/dashboard/investment-product">
                  <div className="ml-[3rem] flex h-[4rem] flex-col w-1/4 text-center hover:border-b-2 cursor-pointer border-b-blue-600  ">
                    <Text className="mt-[1.3rem]">Investment</Text>
                  </div>
                </Link>
              </div>
            </div>

            <Multiligual
              name="languages"
              className="flex flex-col items-end justify-center w-[15%] sm:w-[30%] h-[4rem]   "
            />
            <div className="flex flex-col w-[20%] sm:w-[30%]">
              <div className="flex flex-row items-center pl-5 h-[4rem] gap-x-5">
                <Link to="/dashboard/deposit-history" className="w-full">
                  <Button className="bg-neutral-100 h-[3.3rem]">
                    <Text className="sm:text-[0.88rem] hidden sm:inline">
                      {userInfo?.user?.status !== "missinginfo"
                        ? userInfo?.user?.fullName || null
                        : "N/A"}
                    </Text>
                    <Text className="sm:hidden">Wallet</Text>
                    <Text className="text-sm sm:text-[0.88rem] font-semibold">
                      {" "}
                      {toRinggit(userInfo?.user?.wallet)}
                    </Text>
                  </Button>
                </Link>

                <Link to="/dashboard/profile" className="w-full sm:hidden">
                  <Button className="bg-neutral-100 h-[3.3rem]">
                    <Text>Profile</Text>
                  </Button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col justify-end items-end ml-5 w-[10%] sm:w-[40%] sm:hidden ">
              <Text className="sm:text-[0.88rem]">
                {userInfo?.user?.status !== "missinginfo"
                  ? userInfo?.user?.fullName || null
                  : "N/A"}
              </Text>
              <div className="flex flex-col  ">
                <p className="text-white text-sm font-semibold sm:text-[0.88rem] sm:hidden">
                  GMT+8: {convertTime(currentDateTime)}
                </p>
              </div>
            </div>
            <div
              className=" flex-col justify-center w-[10%] items-end hidden pt-[1rem] sm:flex sm:visible"
              onClick={() => {
                setOpenMenu(!isOpenMenu);
              }}
            >
              <Img src="/images/home/menu.svg" className="h-[100%]" />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          top: `${window.scrollY + 68}px`,
        }}
        className={`absolute z-[99999] h-auto bg-[#ffffff] w-full ${
          !isOpenMenu ? "hidden" : "inline"
        }`}
      >
        <div className="flex flex-col p-5">
          <div
            onClick={() => {
              setOpenMenu(false);
              navigate("/dashboard");
            }}
            className="flex flex-row items-center w-full pb-3 cursor-pointer"
          >
            <div className="flex flex-col w-[20%]">
              {/* <Img className="w-[30px]" src="/images/menu/market.svg" /> */}
              <MdDashboardCustomize size={30} />
            </div>
            <div className="flex flex-col w-[45%] ">
              <Text className="text-[1.3rem] pb-2">Dashboard</Text>
            </div>
            <div className="flex flex-col w-[35%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/dashboard/market-list");
              setOpenMenu(false);
            }}
            className="flex flex-row items-center w-full pb-3 cursor-pointer"
          >
            <div className="flex flex-col w-[20%]">
              {/* <Img className="w-[30px]" src="/images/menu/trade.svg" /> */}
              <MdBookmark size={30} />
            </div>
            <div className="flex flex-col w-[45%]">
              <Text className="text-[1.3rem] pb-2">WatchList</Text>
            </div>
            <div className="flex flex-col w-[35%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div
            onClick={() => {
              // navigate("/dashboard/investment-product");
              // setOpenMenu(false);
              setOpenInvestmentSub(!isOpenInvestmentSub);
            }}
            className="flex flex-row items-center w-full pb-3 cursor-pointer"
          >
            <div className="flex flex-col w-[20%]">
              {/* <Img className="w-[30px]" src="/images/menu/services.svg" /> */}
              <MdNextWeek size={30} />
            </div>
            <div className="flex flex-col w-[45%] ">
              <Text className="text-[1.3rem] pb-2">Investment</Text>
            </div>
            <div className="flex flex-col w-[35%] items-end">
              {!isOpenInvestmentSub ? (
                <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
              ) : (
                <MdKeyboardArrowDown size={30} />
              )}
            </div>
          </div>
          <div
            className={`flex flex-col pb-3 ${
              !isOpenInvestmentSub ? "hidden" : ""
            }`}
          >
            <HeaderInvestmentMenu
              setOpenMenu={setOpenMenu}
              setOpenInvestmentSub={setOpenInvestmentSub}
            />
          </div>
          <div
            onClick={() => {
              navigate("/dashboard/deposit-history");
              setOpenMenu(false);
            }}
            className="flex flex-row items-center w-full pb-3 cursor-pointer"
          >
            <div className="flex flex-col w-[20%]">
              {/* <Img className="w-[30px]" src="/images/menu/trade.svg" /> */}
              <MdWallet size={30} />
            </div>
            <div className="flex flex-col w-[45%]">
              <Text className="text-[1.3rem] pb-2">Wallet</Text>
            </div>
            <div className="flex flex-col w-[35%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div
            onClick={() => {
              // setOpenMenu(false);
              // navigate("/dashboard/profile");
              setOpenProfileSub(!isOpenProfileSub);
            }}
            className="flex flex-row items-center w-full pb-3 cursor-pointer"
          >
            <div className="flex flex-col w-[20%]">
              {/* <Img className="w-[30px]" src="/images/menu/testimonial.svg" /> */}
              <MdOutlinePeople size={30} />
            </div>
            <div className="flex flex-col w-[45%]">
              <Text className="text-[1.3rem] pb-2">Profile</Text>
            </div>
            <div className="flex flex-col w-[35%] items-end">
              {!isOpenProfileSub ? (
                <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
              ) : (
                <MdKeyboardArrowDown size={30} />
              )}
            </div>
          </div>
          <div className={`${!isOpenProfileSub ? "hidden" : ""}`}>
            <HeaderProfileMenu setOpenProfileSub={setOpenProfileSub} />
          </div>
          <div
            onClick={() => {
              setOpenMenu(false);
              handleLogout();
            }}
            className="flex flex-row items-center w-full pb-3 cursor-pointer"
          >
            <div className="flex flex-col w-[20%]">
              <MdLogout size={30} />
            </div>
            <div className="flex flex-col w-[45%] ">
              <Text className="text-[1.3rem] pb-2">Logout</Text>
            </div>
            <div className="flex flex-col w-[35%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          {/* <div className="flex flex-row w-full border-b-2"></div> */}
        </div>
      </div>
    </>
  );
}
